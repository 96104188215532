@font-face {
  font-family: "Monteserrat";
  src: url("../fonts/Montserrat/Montserrat-Light.ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "MonteserratBold";
  src: url("../fonts/Montserrat/Montserrat-Bold.ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "GhotamPro";
  src: url("../fonts/GothamPro/gothampro.ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "GhotamProBold";
  src: url("../fonts/GothamPro/gothampro_bold.ttf");
  font-weight: normal;
  font-style: normal;
}

* {
  font-family: "GhotamPro";
  margin: 0;
}

body::-webkit-scrollbar {
  width: 12px; /* ширина scrollbar */
}
body::-webkit-scrollbar-track {
  background: #f2f5f7; /* цвет дорожки */
}
body::-webkit-scrollbar-thumb {
  background-color: #063350; /* цвет плашки */
  /* border-radius: 20px;       закругления плашки */
  /* border: 3px solid orange;  padding вокруг плашки */
}

@media (max-width: 980px) {
  .mainContent {
    max-width: 90%;
  }

  .testContent {
    max-width: 90%;
  }

  button {
    width: 90%;
  }

  .menuIcon {
    height: 25px;
    width: 25px;
  }

  .headTest {
    min-height: 100px;
    font-size: 2.3rem !important;
  }

  .registrationForm {
    max-width: 400px;
  }

  .registrationContent button {
    width: 80% !important;
  }

  .mainSpan {
    margin-bottom: 60px;
  }

  .forgetBox {
    display: flex;
    flex-direction: column;
  }
}

@media (max-width: 650px) {
  .mainSpan {
    max-width: 400px;
    margin-bottom: 60px;
  }

  .mainContent {
    max-width: 90%;
    height: auto;
  }
  .testContent {
    max-width: 90%;
    height: auto;
  }

  /* .headerButtons {
    max-width: 200px;
  } */

  button {
    max-width: 90%;
  }

  .registrationForm {
    max-width: 350px;
  }

  .pasportCheck {
    max-width: 400px;
    font-size: 14px;
  }

  .menuIcon {
    max-height: 25px;
    max-width: 25px;
    background-size: 25px;
  }

  .headTest {
    min-height: 100px;
    font-size: 2.2rem !important;
  }

  .forgetBox {
    display: flex;
    flex-direction: column;
  }

  .headerButtons button {
    margin-left: 10px;
  }

  .pieChart {
    height: 450px !important;
    width: 450px !important;
  }

  .pieChart canvas {
    height: 450px !important;
    width: 450px !important;
  }

  .questionDescription {
    width: 80% !important;
  }

  .personalText {
    width: 85% !important;
  }

  .succesfulPersonalText {
    width: 85% !important;
  }

  .switchGraphBox {
    width: 300px !important;
    font-size: 0.9rem !important;
  }
}

@media (max-width: 480px) {
  .backGroundLeft {
    filter: blur(0.4rem);
    -webkit-filter: blur(0.4rem);
    transform: translate(-8px);
    opacity: 0.5 !important;
  }

  .backGroundRight {
    filter: blur(0.4rem);
    -webkit-filter: blur(0.4rem);
    opacity: 0.5 !important;
  }

  .header {
    max-width: 480px;
  }

  .testContent {
    margin-top: 100px;
  }

  .mainSpan {
    font-size: 2rem !important;
    max-width: 250px;
    margin-bottom: 60px;
  }

  .registrationForm {
    max-width: 90%;
  }

  .pasportCheck {
    max-width: 250px;
    font-size: 11px;
  }

  .forgetBox {
    display: flex;
    flex-direction: column;
  }

  .headerButtons {
    margin: 0 !important;
  }

  .headerButtons button {
    margin-left: 10px;
  }

  .headTest {
    font-size: 1.8rem !important;
  }

  .questionHolder {
    font-size: 1.1rem !important;
  }

  .final_window {
    width: 250px !important;
    height: auto !important;
    padding-bottom: 10px !important;
    padding-left: 5px !important;
  }

  .final_window span {
    margin-left: 0 !important;
    font-size: 1.1rem !important;
    line-height: 22px !important;
    margin-bottom: 10px !important;
  }

  .pieChart {
    width: 100% !important;

    height: 100% !important;
  }

  .pieChart canvas {
    width: 100% !important;
    height: 100% !important;
  }

  .registrationEnd {
    font-size: 1rem !important;
  }

  .registrationContent button {
    font-size: 1.1rem !important;
  }

  .checkBox span {
    font-size: 1.1rem !important;
  }

  .checkLabel {
    display: flex !important;
    text-align: start !important;
    margin-right: 0 !important;
  }

  .personalConentItem {
    flex-direction: column !important;
    text-align: center;
    font-size: 1.1rem !important;
  }

  .switchGraphBox {
    width: 95% !important;
  }

  .result {
    width: 90% !important;
  }
}

button {
  cursor: pointer;
  /* box-shadow: -2px 2px 9px rgba(0, 0, 0, 0.25); */
}

a {
  outline: none;
  text-decoration: none;
  padding: 2px 1px 0;
}

a:link {
  color: #3b72b8;
}

a:visited {
  color: #3b72b8;
}

a:focus {
  color: #3b71b898;
}

a:hover {
  color: #3b71b898;
}

a:active {
  color: #3b71b898;
}

.backGroundLeft {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.backGroundRight {
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
  height: 100%;
}

.header-color {
  width: 100%;
}

.header {
  width: 100%;
  height: 100px;
  /* background-color: #3b72b8; */
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer {
  margin-top: 15%;
  background-color: #063350;
  padding: 20px;
  color: #fff;
}

.footerContent {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 80%;
  margin-top: 30px;
}

.footerButton {
  cursor: pointer;
  margin-bottom: 10px;
  color: #000;
}

.footerButton:link {
  color: #dadada;
}

.footerButton:visited {
  color: #000;
}

.footerButton:focus {
  color: #747474;
}

.footerButton:hover {
  color: #000000a6;
}

.footerButton:active {
  color: #747474;
}

.logoBox {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.logo {
  margin-left: 5px;
  cursor: pointer;
  font-size: 32px;
  font-weight: 700;
  line-height: 39px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
  /* margin-left: 50px */
}

.logoFooter {
  /* color: #000; */
  margin-top: 20px;
}

.headerButtons {
  /* width: 280px; */
  display: flex;
  justify-content: space-around;
  margin-right: 50px;
}

.headerButtons button {
  border-radius: 12px;
  background: #4d7fbe;
  border: none;
  color: white;
  width: 135px;
  height: 38px;
  font-size: 1rem;
}

.mainSpan {
  display: flex;
  margin: 50px auto;
  justify-content: center;
  font-size: 2.25rem;
  font-weight: bold;
}

.mainPageSpan {
  text-align: center;
  margin: auto;
  font-size: 1.5rem;
}

.personalSpan {
  text-align: center;
  margin-top: 50px;
  margin-bottom: 20px;
  margin-left: 15px;
  display: flex;
  font-size: 25px;
  font-weight: 700;
  flex-direction: column;
  justify-content: space-around;
}

.personalConentItem {
  font-size: 1.4rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 10px;
}

.personalConentItem div {
  margin-left: 5px;
  font-family: "GhotamProBold";
}

.personalText {
  margin: 0 auto;
  width: 800px;
  font-size: 20px;
}

.personalText div {
  text-indent: 35px;
  margin-bottom: 15px;
  line-height: 28px;
  text-align: justify;
}

.succesfulPersonalText {
  margin: 0 auto;
  margin-bottom: 40px;
  text-align: justify;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 15px;
  padding-top: 15px;
  /* border: solid 1px #3b72b8; */
  border-radius: 12px;
  font-size: 20px;
  width: 800px;
}

.succesfulPersonalText p {
  margin-bottom: 15px;
  line-height: 28px;
}

.switchGraphBox {
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  width: 500px;
  border-radius: 12px;
  font-size: 24px;
  border: 1px solid #bcd2d0;
}

.switchGraphBox div {
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
  width: 100%;
  cursor: pointer;
}

.chosenGraphSwitch {
  background-color: #3b72b8 !important;
  color: #fff;
  cursor: default !important;
}

.switchGraphBox div:hover {
  background-color: #8ebfff;
  color: #fff;
  transition: 0.25s;
}

.switchGraphLeft {
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
}

.switchGraphRight {
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
}

.friendText {
  margin-bottom: 20px !important;
}

.pieChart {
  margin: auto;
  height: 650px;
  width: 650px;
}

.socialStatus {
  margin: 0 auto;
  margin-top: 30px;
  font-size: 1.4rem;
  text-align: center;
  font-family: "GhotamProBold";
  margin-bottom: 20px;
  max-width: 320px;
}

.socialStatus div {
  margin-top: 10px;
}

.socialStatusNumber {
  color: #8ebfff;
  font-weight: bold;
}

.socialLevelNumber {
  color: #4d7fbe;
  font-weight: bold;
}

.mainSpan span {
  font-size: 34px;
  font-weight: 400;
  line-height: 40px;
  letter-spacing: 0em;
  text-align: left;
}

#registrationApprove span {
  font-size: 0.9em;
}

.warningRegistration {
  text-align: center;
}

.mainContent {
  min-height: 500px;
  max-height: auto;
  max-width: 1000px;
  border-radius: 10px;
  box-shadow: 4px 4px 15px 0px #00000040;
  margin: 0 auto;
  margin-top: 30px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 9;
}

.mainContent button {
  width: 400px;
  height: 50px;
  margin-top: 30px;
  margin: auto;
  border-radius: 12px;
  /* background: #4d7fbe; */
  font-size: 1.5rem;
  /* border: none; */
  /* color: white; */
}

.mainContent span {
  margin-top: 20px;
  margin: auto;
  text-align: center;
}

.testContent {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: auto;
  margin-bottom: 15px;
}

.radioInput {
  width: 300px;
  margin-bottom: 15px;
}

.checkBox {
  align-items: flex-start;
}

.checkBox span {
  text-align: start;
  font-size: 1.4rem;
  padding-top: -1px;
}

#simple-select {
  width: 200px;
  font-size: 1.3rem;
}

#demo-simple-select-label {
  font-size: 1.3rem;
}

.testOption {
  font-size: 1.4rem;
}

#text-input {
  font-size: 1.4rem;
}

#text-input-label {
  font-size: 1.4rem;
}

.headTest {
  margin-top: 30px;
  line-height: initial;
  font-size: 35px;
  display: flex;
  flex-direction: column;
}

.questionHolder {
  font-size: 1.3rem;
}

.qustionMark {
  margin-left: 10px;
  margin-bottom: 20px;
  cursor: pointer;
}

.questionDescription {
  position: absolute;
  width: 450px;
  /* height: 200px; */
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  background-color: #f2f5f7;
  border: 1px solid #bcd2d0;
  border-radius: 12px;
  z-index: 10;
  font-size: 20px;
  line-height: 28px;
  text-align: justify;
}

.descriptionCloseBox {
  display: flex;
  width: 100% !important;
  margin: 0 !important;
  /* align-content: center !important; */
}

.descriptionCloseBox img {
  cursor: pointer;
  transition: 0.25s;
}

.descriptionCloseBox img:hover {
  scale: 1.05;
}

.questionDescriptionText {
  margin-top: 5px;
}

ul {
  /* margin-top: 10px; */
  margin-bottom: 5px;
  font-size: 20px !important;
  text-align: start;
  /* display: grid;
  grid-template-columns: auto auto auto; */
}

.pop_up {
  background: rgba(102, 102, 102, 0.5);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  overflow: hidden;
  z-index: 100;
}

.pop_window {
  position: absolute;
  margin-top: 200px;
  padding-bottom: 30px;
  background-color: white;
  border-radius: 10px;
}

.final_window {
  padding-top: 10px;
  width: 400px;
  display: flex;
  flex-direction: column;
}

.final_window span {
  margin: 0 auto;
  white-space: pre-wrap;
  font-size: 1.3rem;
  line-height: 27px;
  height: 320px;
}

.finalMessage {
  margin-left: 15px;
  margin-right: 15px;
  text-align: center;
}

.enterButton,
.exitButton {
  border-radius: 12px;
  background: #4d7fbe;
  border: none;
  color: white;
  width: 135px;
  height: 38px;
  font-size: 15px;
  margin: auto;
}

.exitButton {
  margin-top: 20px;
}

.enter {
  height: 400px;
  color: black;
  padding: 5px;
}

.enter span {
  margin: 0 auto;
  font-weight: 900;
  font-size: 24px;
}

.result {
  height: 390px;
  width: 360px;
  color: black;
  padding: 5px;
}

.result button {
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.btn {
  /* width: 60%; */
  padding-top: 10px;
  padding-bottom: 10px;
  /* padding: 10px 200px; */
  /* outline: none; */
  border: 1px solid #bcd2d0;
  color: #222538;
  background: #f2f5f7;
  font-size: 1.3rem;
  border-radius: 12px;
  cursor: pointer;
  transition: 0.25s;
  margin: 0 auto;
  display: flex;
  justify-content: center;
}

.btn span {
  line-height: 27px;
}

.loadingBox {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  width: 100%;
}

.loadingBox button {
  gap: 10px;
}

.nextBox {
  margin: auto;
  display: flex;
  justify-content: center;
  width: 100%;
}

.nextBox button {
  display: flex;
  align-items: center;
  border: 1px solid #bcd2d0;
  color: #222538;
  background: #f2f5f7;
  border-radius: 12px;
  cursor: pointer;
  transition: 0.25s;
}

.nextBox button:hover {
  background-color: #3b72b8;
  color: #fff;
}

.agreementText {
  color: #6f6f6f;
}

.btn:hover {
  /* transform: scale(1.05); */
  background-color: #3b72b8;
  color: #fff;
}

.EnterComponents {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 80%;
  margin: auto;
  align-items: center;
  height: 90%;
  gap: 1.25rem;
}

.enterTitle {
  color: black;
  font-size: 1.5rem;
}

.btn_Enter {
  width: 100%;
  padding-top: 6px;
  padding-bottom: 6px;
  /* padding: 10px 200px; */
  outline: none;
  border: none;
  color: #fff;
  background: #3b72b8;
  font-size: 1.5rem;
  border-radius: 50px;
  cursor: pointer;
  transition: 0.5s;
  margin-top: 5%;
}

.btn_Enter:hover {
  transform: scale(1.02);
}

.goResult {
  font-size: 0.9rem !important;
  font-family: "GhotamProBold";
  background: #ee1c43 !important;
}

.textInputEnter {
  width: 95%;
}

.forgetBox {
  margin-top: 20px;
  display: flex;
  align-items: center;
}

.forgetBox button {
  background: none;
}

.forgetPassword {
  min-width: 140px;
  border: none;
  color: rgba(0, 0, 0, 0.26);
  font-size: 0.9rem;
}

.forgetRegistrate {
  border: none;
  color: black;
  font-size: 0.9rem;
  text-decoration: underline;
}

.closeBox {
  width: 95%;
  display: flex;
  justify-content: flex-end;
  margin: 5px;
}

.closeBox button:hover {
  /* transform: scale(1.08); */
  background: #4d7fbe;
  border: none;
  color: white;
}

.closeBox button {
  border-radius: 15px;
  border: 1px solid #bcd2d0;
  color: #222538;
  background: #f2f5f7;
  width: 24px;
  height: 25px;
  font-size: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.25s;
}

.registrationContent {
  width: 100%;
}

.registrationForm {
  width: 400px;
  display: flex;
  flex-direction: column;
  margin: auto;
  margin-top: 30px;
  height: 700px;
  justify-content: space-around;
}

.registrationForm a {
  margin-bottom: 5px;
}

.drag-area {
  /* border: 2px dashed #4d7fbe; */

  border: 2px dashed #6f6f6fb2;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 10px auto;
  padding: 10px;
}
.drag-area.active {
  /* border: 2px solid #4d7fbe; */
  border: 2px dashed #6f6f6fb2;
}
.drag-area .icon {
  font-size: 50px;
  /* color: #4d7fbe; */
  color: #6f6f6fb2;
}
.drag-area header {
  font-size: 20px;
  font-weight: 500;
  /* color: #4d7fbe; */
  color: #6f6f6fb2;
}
.drag-area span,
.drag-pasport span {
  font-size: 10px;
  font-weight: 500;
  /* color: #4d7fbe; */
  color: #6f6f6fb2;
  margin: 10px 0 15px 0;
}
.drag-area button {
  padding: 10px 25px;
  font-size: 20px;
  font-weight: 500;
  border: none;
  outline: none;
  /* background: #4d7fbe; */
  background: #6f6f6f25;
  color: #fff;
  border-radius: 5px;
  /* cursor: pointer; */
}
.drag-area img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 5px;
}

.dropText {
  word-break: break-all;
}

.dropPasport {
  word-break: break-all;
}

.pasportLabel {
  margin: 0 auto;
  font-weight: 900;
  font-size: 20px;
  text-align: start !important;
}

.pasportForm {
  border: solid 1px black;
  border-radius: 5px;
  display: none;
  flex-direction: column;
  justify-content: space-around;
  padding: 10px;
  gap: 1.2rem;
}

.registrationEndBox {
  display: flex;
  justify-content: center;
}

.registrationEnd {
  border-radius: 12px;
  background: #4d7fbe;
  border: none;
  color: white;
  display: flex;
  justify-content: center;
  padding: 10px;
  gap: 20px;
  font-size: 20px;
}

.registrationEnd span {
  line-height: 27px !important;
}

.menuIcon {
  height: 50px;
  width: 50px;
  background-image: url(../src/icons/menu.png);
  cursor: pointer;
  margin-left: 10px;
}

.sideBarImage {
  height: 30px;
  width: 30px;
}

.FIO {
  color: #fff;
  font-size: 2rem;
  margin-right: 50px;
  margin-left: 10px;
}

.sideBar {
  position: absolute;
  left: 0;
  top: 0;
  color: black;
  background-color: white;
  width: 220px;
}

.sideApp {
  display: flex;
  flex-direction: column;
}

.sideButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
  cursor: pointer;
  width: 210px;
  height: 25px;
  padding: 5px;
  font-size: 16px;
}

.sideButton:hover {
  background-color: rgba(139, 138, 138, 0.26);
}

.sideButtonBox {
  margin-top: 5px;
}

.smart-captcha {
  margin: 50px auto;
  max-width: 300px;
}

.ver {
  position: absolute;
  right: 15px;
}
